@import "../../../../styles/common_less.module.less";

.report {
    width:          100%;
    margin:         0 auto;
    padding-bottom: 30px;
    display:        flex;
    flex-direction: column;
    font-family:    'Montserrat', sans-serif;
    font-weight:    400;
    color:          @gray1;
    padding-top:    70px;

    .container {
        box-sizing: border-box;
        min-width:  310px;
        max-width:  1170px;
        margin:     0 auto;
        width:      100%;
        padding:    0 16px;
    }
}

.flexWrap {
    display:         flex;
    justify-content: space-between;
}

.imageContainer {
    position: relative;
    display:  inline-block;
}

.box {
    color:            #fff;
    background-color: @green1;
    text-align:       center;
    padding:          15px 23px 15px;
    box-sizing:       border-box;
    border-radius:    11px;
    margin-bottom:    16px;

    &Title {
        font-size:      17px;
        font-style:     normal;
        font-weight:    600;
        line-height:    20.794px; /* 122.318% */
        letter-spacing: 0.051px;
        margin-bottom:  18px;
    }

    &Gray {
        background-color: @gray9;
        color:            @green1;
    }

    &Green {
        background-color: @green6;
        color:            @green1;
    }

    &Effort {
        padding: 15px 23px 15px;

        .img {
            max-width: 180px;
        }

        .imageContainer {
            position: relative;
        }

        &Text {
            font-size:      18px;
            font-style:     normal;
            font-weight:    600;
            line-height:    24px; /* 133.333% */
            letter-spacing: 0.054px;
            margin-top:     16px;
        }

        .indicator {
            position: absolute;
            display:  flex;
            left:     60px;
            height:   145px;
            width:    24px;
        }

        .cursorIcon {
            rotate: 60deg;
        }

        .overlayText {
            position:    absolute;
            bottom:      -14px;
            left:        49%;
            transform:   translateX(-50%);
            color:       white;
            padding:     0 0;
            text-align:  center;
            z-index:     1;
            background:  @green1;
            width:       40px;
            height:      40px;
            font-size:   20px;
            font-style:  normal;
            font-weight: 600;
            line-height: 21.6px;

            &Min {
                position:    absolute;
                left:        -15px;
                bottom:      3px;
                font-size:   10.59px;
                font-style:  normal;
                font-weight: 500;
                line-height: 13.01px;
                color:       #fff;
            }

            &Max {
                position:    absolute;
                right:       -25px;
                bottom:      3px;
                font-size:   10.59px;
                font-style:  normal;
                font-weight: 500;
                line-height: 13.01px;
                color:       #fff;
            }
        }
    }

    &Info {
        text-align:   left;
        padding-left: 9px;
        background:   @gray7;
        box-shadow:   0 0 11.346px 0 rgba(0, 0, 0, 0.08);

        &Icon {
            min-width:    31px;
            min-height:   31px;
            margin-right: 4px;
        }

        .boxTitle {
            font-size:      12px;
            font-style:     normal;
            font-weight:    700;
            line-height:    17px; /* 141.667% */
            letter-spacing: 0.036px;
            color:          @green1;
            margin-bottom:  0;
        }

        .boxText {
            font-size:      12px;
            font-style:     normal;
            font-weight:    400;
            line-height:    17px;
            letter-spacing: 0.036px;
            color:          @green1;
        }
    }

    &Last {
        padding: 16px 17px;

        .boxTitle {
            font-size:      12px;
            font-style:     normal;
            font-weight:    700;
            line-height:    19px; /* 158.333% */
            letter-spacing: 0.036px;
            margin-bottom:  2px;
        }

        .boxText {
            font-size:      12px;
            font-style:     normal;
            font-weight:    400;
            line-height:    19px;
            letter-spacing: 0.036px;
        }
    }
}

.boxGray .boxTitle {
    color: @green1;
}

.habitScore {
    padding: 16px 10px;

    &List {
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        max-width:       fit-content;
        margin:          0 auto;

        &Item {
            display:       flex;
            align-items:   center;
            margin-bottom: 8px;

            &Title {
                flex:           0 0 auto;
                min-width:      150px;
                margin-right:   16px;
                font-size:      11px;
                font-style:     normal;
                font-weight:    400;
                line-height:    19.8px; /* 182.412% */
                letter-spacing: 0.033px;
                color:          @green1;
                text-align:     right;
            }

            &Range {
                display:       block;
                width:         100%;
                max-width:     113px;
                position:      absolute;
                height:        100%;
                border-radius: 5px;
                top:           0;
                left:          0;
                min-width:     12px;

                &Container {
                    position: relative;
                    height:   6.6px;
                    width:    113px;
                }
            }
        }
    }
}

.boxBedTime {
    padding: 16px 11px 11px;

    .boxTitle {
        margin-bottom: 0;
    }

    .boxSubtitle {
        font-size:      12px;
        font-style:     normal;
        font-weight:    400;
        line-height:    16.876px;
        letter-spacing: 0.036px;
        margin-bottom:  8px;
    }

    &Image {
        max-width:  700px;
        min-height: 200px;
        margin:     0 auto;
    }
}

.boxHabits .boxTitle {
    margin-bottom: 5px;
}

.boxHabits .boxText {
    font-size:      12px;
    font-style:     normal;
    font-weight:    400;
    line-height:    17px;
    letter-spacing: 0.036px;
}

.textUnderlined {
    text-decoration-line:      underline;
    text-decoration-style:     solid;
    text-decoration-skip-ink:  none;
    text-decoration-thickness: auto;
    text-underline-offset:     auto;
    text-underline-position:   from-font;
}

.habitsList {
    font-size:       12px;
    font-style:      normal;
    font-weight:     600;
    line-height:     17px;
    letter-spacing:  0.036px;
    margin:          5px auto 0;
    max-width:       400px;
    width:           100%;
    text-align:      center;
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     baseline;

    li {
        padding:    0;
        margin:     0;
        text-align: left;
    }
}

.divider {
    height:           0.5px;
    width:            90%;
    background-color: #fff;
    opacity:          0.6;
    margin:           10px auto;
}

.habitsAffect {
    padding: 16px;

    &InfoWrap {
        display:       flex;
        margin-bottom: 15px;
    }

    &InfoTitle {
        font-size:      14px;
        font-style:     normal;
        font-weight:    700;
        line-height:    19.65px; /* 147.231% */
        letter-spacing: 0.04px;
        text-align:     left;
        padding-bottom: 4px;
    }

    &InfoText {
        font-size:      14px;
        font-style:     normal;
        font-weight:    400;
        line-height:    19.65px;
        letter-spacing: 0.04px;
        text-align:     left;
    }
}

.iconPlus, .iconMinus {
    width:           40px;
    min-width:       40px;
    min-height:      40px;
    height:          40px;
    border-radius:   50%;
    display:         flex;
    align-items:     center;
    justify-content: center;
    font-size:       30px;
    font-weight:     bold;
    color:           white;
    border:          none;
    cursor:          pointer;
    margin-right:    15px;
    box-shadow:      2px 2px 5px rgba(0, 0, 0, 0.3);
    user-select:     none;
}

.iconPlus {
    background: radial-gradient(circle, #6cc24a, #2d7d19);
}

.iconMinus {
    background: radial-gradient(circle, #c83228, #7d1410);
}

.contributingHabits {
    padding: 16px 30px 16px 0;

    .flexWrap {
        max-width: 222px;
        margin:    10px auto 0;
    }
}

.helper {
    display:     flex;
    align-items: baseline;

    &Icon {
        min-width: 12px;
    }

    &Text {
        font-size:      11.28px;
        font-style:     normal;
        font-weight:    600;
        line-height:    13.798px; /* 122.318% */
        letter-spacing: 0.034px;
    }
}

.helperText.green-text {
    color: @green4;
}

.helperText.red-text {
    color: @danger;
}